import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, NumberValidationRule } from '../../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        updating: false,
        loadingPage: false,
        title: "",
        url: "",
        type: "",
        keywords: "",
        description: "",
        keyBody: Math.random(),
        body: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadPage = instance => {
    instance.setState({
        ...instance.state,
        loadingPage: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/content/page",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadPageResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadPageResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingPage: false,
                title: response.data.data.page.title,
                url: response.data.data.page.url,
                type: response.data.data.page.type,
                id: response.data.data.page.id,
                keywords: response.data.data.page.keywords,
                description: response.data.data.page.description,
                body: response.data.data.page.body,
                keyBody: Math.random(),
                flag: {
                    type: "",
                    text: ""
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingPage: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.url, (error) => errors.url = error, { min: { value: 2, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.keywords, (error) => errors.keywords = error, { min: { value: 0, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => errors.description = error, { min: { value: 0, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.type, (error) => errors.type = error, { min: { value: 0, error: "Too low" }, max: { value: 5, error: "Too high" } })
    validation.addValidationRule(StringValidationRule, instance.state.body, (error) => errors.body = error, { min: { value: 5, error: "Too short" }, max: { value: 100000, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const updatePage = (instance) => {
    instance.setState({
        ...instance.state,
        updating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/content/page/update",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            title: instance.state.title,
            url: instance.state.url,
            type: instance.state.type,
            keywords: instance.state.keywords,
            description: instance.state.description,
            body: instance.state.body,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleUpdatePageResponse(instance, response.data))
        .catch(error => alert(error))
}

const handleUpdatePageResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    ScrollIntoViewById("editPage")
    switch (response.status) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "success",
                    text: "Page Updated"
                }
            })
            
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.message
                }
            })
    }
}

const UpdatePageWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        loadPage: () => loadPage(instance),
        updatePage: () => updatePage(instance)
    }
}

export default UpdatePageWorker