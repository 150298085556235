import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import { LoadAuthors, LoadJournals, LoadArticleTypes, IsValid } from '../article.worker'
import { ScrollIntoViewById } from '../../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        updating: false,
        loadingJournals: false,
        loadingTypes: false,
        loadingArticle: false,
        showAddAuthor: false,
        showUploadPdf: false,
        title: "",
        doi: "",
        journalId: "",
        articleTypeId: "",
        keywords: "",
        description: "",
        abstract: "",
        fullText: "",
        contentType: "1",
        publicationDate: "",
        pageStart: "",
        pageEnd: "",
        keyFullText: Math.random(),
        keyAbstract: Math.random(),
        pdf: "",
        errors: {},
        journals: [],
        authors: [],
        articleTypes: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadArticle = instance => {
    instance.setState({
        ...instance.state,
        loadingArticle: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleLoadArticleResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadArticleResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingArticle: false,
                title: response.data.data.article.title,
                pageStart: response.data.data.article.pageStart,
                pageEnd: response.data.data.article.pageEnd,
                journalId: response.data.data.article.journal.id,
                articleTypeId: response.data.data.article.articleType.id,
                keywords: response.data.data.article.keywords,
                description: response.data.data.article.description,
                abstract: response.data.data.article.abstract,
                fullText: response.data.data.article.fullText,
                contentType: response.data.data.article.fullText.length > 0 ? "2" : "1",
                doi: response.data.data.article.doi,
                pdf: response.data.data.article.pdf,
                authors: response.data.data.article.authors,
                publicationDate: new Date(response.data.data.article.publicationDate).toString("yyyy-MM-dd"),
                keyFullText: Math.random(),
                keyAbstract: Math.random(),
                flag: {
                    type: "",
                    text: ""
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                loadingArticle: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const updateArticle = (instance) => {
    instance.setState({
        ...instance.state,
        updating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/article/update",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            id: instance.props.match.params.id,
            title: instance.state.title,
            doi: instance.state.doi,
            pageStart: instance.state.pageStart,
            pageEnd: instance.state.pageEnd,
            journalId: instance.state.journalId,
            articleTypeId: instance.state.articleTypeId,
            keywords: instance.state.keywords,
            description: instance.state.description,
            publicationDate: instance.state.publicationDate,
            abstract: instance.state.abstract,
            fullText: instance.state.fullText,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleUpdateArticleResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                updating: false
            })
            alert(error)
        })
}

const handleUpdateArticleResponse = (instance, response) => {
    if (response.status !== 200) {
        return
    }
    ScrollIntoViewById("editArticle")
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "success",
                    text: "Article Updated"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                updating: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const UpdateArticleWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => IsValid(instance),
        loadJournals: () => LoadJournals(instance),
        loadArticleTypes: () => LoadArticleTypes(instance),
        loadAuthors: () => LoadAuthors(instance),
        loadArticle: () => loadArticle(instance),
        updateArticle: () => updateArticle(instance)
    }
}

export default UpdateArticleWorker