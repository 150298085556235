import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import store from '../../../../redux/stores/store'
import Validation from '../../../../data/validation/validation'
import { StringValidationRule, NumberValidationRule } from '../../../../data/validation/rules'
import { ScrollIntoViewById } from '../../../../utils/UIHelper'

const getDefaultState = () => {
    return {
        creating: false,
        loadingJournals: false,
        title: "",
        url: "",
        type: "",
        keywords: "",
        description: "",
        keyBody: Math.random(),
        body: "",
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.title, (error) => errors.title = error, { min: { value: 2, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.url, (error) => errors.url = error, { min: { value: 2, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.keywords, (error) => errors.keywords = error, { min: { value: 0, error: "Too short" }, max: { value: 100, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.description, (error) => errors.description = error, { min: { value: 0, error: "Too short" }, max: { value: 300, error: "Too long" } })
    validation.addValidationRule(NumberValidationRule, instance.state.type, (error) => errors.type = error, { min: { value: 0, error: "Too low" }, max: { value: 5, error: "Too high" } })
    validation.addValidationRule(StringValidationRule, instance.state.body, (error) => errors.body = error, { min: { value: 5, error: "Too short" }, max: { value: 100000, error: "Too long" } })
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

const createPage = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/api/content/page/create",
        headers: {
            "Authorization": store.getState().auth.authorization
        },
        data: {
            title: instance.state.title,
            url: instance.state.url,
            type: instance.state.type,
            keywords: instance.state.keywords,
            description: instance.state.description,
            body: instance.state.body,
            Authorization: store.getState().auth.authorization
        }
    }).then(response => handleCreatePageResponse(instance, response.data))
    .catch(error => alert(error))
}

const handleCreatePageResponse = (instance, response) => {
    if(response.status !== 200){
        return
    }
    ScrollIntoViewById("createPage")
    switch (response.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Page Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                text: response.message
                }
            })
    }
}

const CreatePageWorker = instance => {
    return {
        getDefaultState: getDefaultState,
        isValid: () => isValid(instance),
        createPage: () => createPage(instance)
    }
}

export default CreatePageWorker