import React from "react";
import CKEditor from "ckeditor4-react-advanced";

const PageForm = (props) => {
  return (
    <form>
      <div className="inputs">
        <div className="row">
          <div className="form-label">
            <label>Journals</label>
          </div>
          <div className="form-input">
            <select
              name="journalId"
              value={props.journalId}
              onChange={props.onJournalChange}
            >
              <option value=""></option>
              {props.journals.map((journal, key) => (
                <option key={key} value={journal.id}>
                  {journal.title}
                </option>
              ))}
            </select>
            <span className="error">{props.errors.journalId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Title</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="title"
              value={props.title}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.title}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Url</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="url"
              value={props.url}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.url}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Parent</label>
          </div>
          <div className="form-input">
            <select
              name="parentId"
              value={props.parentId}
              onChange={props.onChange}
            >
              <option value=""></option>
              {props.pages.filter(page => page.title !== props.title && page.parent === null).map((page, key) => (
                <option key={key} value={page.id}>
                  {page.title}
                </option>
              ))}
            </select>
            <span className="error">{props.errors.parentId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Keywords</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="keywords"
              value={props.keywords}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.keywords}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Ordering</label>
          </div>
          <div className="form-input">
            <input
              type="number"
              name="ordering"
              value={props.ordering}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.ordering}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-label">
            <label>Description</label>
          </div>
          <div className="form-input">
            <input
              type="text"
              name="description"
              value={props.description}
              onChange={props.onChange}
            />
            <span className="error">{props.errors.description}</span>
          </div>
        </div>
        <div className="row editor">
          <div className="form-label">
            <label>Content</label>
          </div>
          <CKEditor key={props.keyBody}
            data={props.body}
            config={{
              width: "100%",
              versionCheck: false
            }}
            onChange={(event) => {
              const data = event.editor.getData()
              props.onChange({
                target: {
                  name: "body",
                  value: data
                }
              })
            }}
          />
          <span className="error">{props.errors.body}</span>
        </div>
      </div>
      <div className="actions">
        {props.creating || props.updating ? (
          <button type="submit" onClick={props.onSubmit} disabled={true}>
            wait...
          </button>
        ) : (
          <button type="submit" onClick={props.onSubmit}>
            {props.action}
          </button>
        )}
        <button type="reset" onClick={props.onCancel}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PageForm;
